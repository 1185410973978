import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import LandingPageLink from 'components/landing/LandingPageLink';
import LandingPageMedia from 'components/landing/LandingPageMedia';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import HtmlToReact from 'components/common/HtmlToReact';
import { getIsGoodsLabelPage } from 'utils/landing';
import { IS_PRINT_PROTOCOL } from 'common/regex';
import { selectPathname } from 'selectors/router';
import { dashOrSnakeCaseToCamelCaseDeep } from 'helpers/DataFormatUtils';

import css from 'styles/components/common/contentBox.scss';

const flexAlignStyleMap = {
  left: 'flex-start', // this is default in the scss
  center: 'center',
  right: 'flex-end'
};

const flexAlignStyleMapCss = {
  left: css.linksLeft,
  center: css.linksCenter,
  right: css.linksRight
};

export const ContentBox = ({
  heading,
  styles,
  HeadingTag = 'h2',
  copy,
  subCopy,
  className,
  children,
  imageData,
  links = [],
  onClick,
  eventLabel,
  useImageAsHeading,
  shouldLazyLoad,
  backgroundColor,
  color,
  mobileColor,
  textAlign,
  contentWidth,
  isFullWidth,
  pageName,
  pageInfo = {}
}) => {
  const { testId } = useMartyContext();
  const { containerStyle, headingStyle, copyStyle, linksStyle } = dashOrSnakeCaseToCamelCaseDeep(styles) || {};
  const pathname = useSelector(selectPathname);
  const isGoodsLabelPage = getIsGoodsLabelPage(pageName);
  const getIsLabelLink = link => isGoodsLabelPage && IS_PRINT_PROTOCOL.test(link) && pageInfo.packageLabelUrl;

  const getLinkUrl = slotLink => {
    if (getIsLabelLink(slotLink)) {
      if (!pageInfo.packageLabelUrl) {
        return pathname;
      }

      return pageInfo.packageLabelUrl;
    }

    return slotLink;
  };

  const getLinkText = (slotText, slotLink) => {
    if (getIsLabelLink(slotLink)) {
      if (!pageInfo.packageLabelUrl) {
        return 'Failed to create label. Please try again.';
      }
    }

    return slotText;
  };

  // Check that we want to render the ContentBox first
  if (heading || copy || subCopy || imageData || links?.[0]?.text) {
    const onlyLink = !heading && !copy && !subCopy && links?.[0]?.text;

    const style = {
      textAlign,
      backgroundColor,
      '--desktop-text-color': color,
      '--mobile-text-color': mobileColor || color,
      'borderColor': color,
      'alignItems': flexAlignStyleMap[textAlign],
      'width': contentWidth,
      ...containerStyle
    };

    const headingStyles = {
      ...headingStyle
    };

    const hideHeadingForImg = useImageAsHeading && imageData?.alt;
    const ImgWrapper = hideHeadingForImg ? HeadingTag : Fragment;
    const imgWrapperProps = hideHeadingForImg ? { style: headingStyle } : {};

    return (
      <div
        style={style}
        className={cn(
          css.container,
          className,
          { [css.onlyLink]: onlyLink },
          {
            // Leaving this commented out as we may revert back to this after the holiday
            // * HOLIDAY STYLING - easy search tag for cleanup
            // [css.fullWidth]: isFullWidth
          }
        )}
        data-test-id={testId(`contentBox:${eventLabel || heading}`)}
      >
        <ImgWrapper {...imgWrapperProps}>
          <LandingPageMedia {...imageData} shouldLazyLoad={shouldLazyLoad} isFullWidth={isFullWidth} />
        </ImgWrapper>
        {heading && !hideHeadingForImg && (
          <HeadingTag className={css.heading} style={headingStyles}>
            {heading}
          </HeadingTag>
        )}
        {copy && (
          <HtmlToReact style={copyStyle} className={css.copy}>
            {copy}
          </HtmlToReact>
        )}
        {subCopy && <HtmlToReact className={css.subCopy}>{subCopy}</HtmlToReact>}
        {children}
        {!!links.length && (
          <div className={cn(css.links, flexAlignStyleMapCss[textAlign])}>
            {links.map(({ href, text, newWindow, gae }) => (
              <LandingPageLink
                style={linksStyle}
                className={cn(
                  'text-small z-10 box-border inline-flex cursor-pointer items-center border-none bg-button-transparent font-button font-semibold text-link-default underline underline-offset-4 antialiased outline-2 outline-offset-2 outline-active transition focus-visible:outline disabled:cursor-not-allowed disabled:text-link-disabled sm:text-base',
                  css.link
                )}
                onClick={onClick}
                newWindow={newWindow}
                url={getLinkUrl(href)}
                key={href + text + gae}
                data-eventvalue={gae || text}
                data-eventlabel={eventLabel}
                target={getIsLabelLink(href) ? '_blank' : '_self'}
              >
                <span className="flex items-center gap-x-2 py-px">{getLinkText(text, href)}</span>
              </LandingPageLink>
            ))}
          </div>
        )}
      </div>
    );
  }

  return null;
};

ContentBox.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  HeadingTag: PropTypes.string,
  copy: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      newWindow: PropTypes.bool,
      gae: PropTypes.string
    })
  ),
  eventLabel: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  shouldLazyLoad: PropTypes.bool,
  useImageAsHeading: PropTypes.bool,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  textAlign: PropTypes.string,
  contentWidth: PropTypes.string,
  imageData: PropTypes.shape({
    type: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.object),
    src: PropTypes.string,
    srcset: PropTypes.string,
    alt: PropTypes.string
  }),
  isFullWidth: PropTypes.bool
};

export default withErrorBoundary('ContentBox', ContentBox);
